import { type } from 'redux/types/index.type';
import { IUIModel } from 'models/auth.model';
import { IActionRedux } from 'models/global.model';
import UiInitialState from '../initialState/ui-initial-state';

const uiReducer = (state: IUIModel = UiInitialState, action: IActionRedux) => {
  switch (action.type) {
    case type.uiAside:
      return { ...state, aside: { ...state.aside, open: !state.aside.open } };

    case type.uiAsideHandleAnimation:
      return {
        ...state,
        aside: { ...state.aside, animationAside: !state.aside.animationAside },
      };

    case type.uiHandleIsMobile:
      return { ...state, aside: { ...state.aside, isMobile: action.payload } };

    case type.uiHandleMenuAside:
      return { ...state, aside: { ...state.aside, ...action.payload } };

    case type.uiHandleLoading:
      return { ...state, loading: !state.loading };

    case type.uiHandleOptionalLoading:
      return {
        ...state,
        optionalLoading:
          action.payload !== undefined
            ? action.payload
            : !state.optionalLoading,
      };

    default:
      return state;
  }
};

export default uiReducer;
