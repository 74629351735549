import { type } from 'redux/types/index.type';

export function setSubscription(payload: any) {
  return (dispatch: any, getState: any) => {
    let {
      subscription: { subscription },
    } = getState();
    subscription = { ...subscription, ...payload.subscription };
    dispatch({
      type: type.subscriptionSet,
      payload: { ...payload, subscription },
    });
  };
}
