import { Button, Card, Col, Grid, Row, Space, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { useHistory } from 'react-router-dom';
import { getLargeDate, getMount, getPriceLicense } from 'utils/subscriptions';
import RowFields from './confirmation/RowFields';

const { useBreakpoint } = Grid;
const { Title, Text, Link } = Typography;
const styleTitle = {
  margin: 0,
};
const ConfirmationPayment = () => {
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const { mailBox, licenses }: any = useStateRedux('auth');
  const { subscription }: any = useStateRedux('subscription');
  const { card, customer, next_billing_at, plan } = subscription;
  const price = getPriceLicense(
    next_billing_at,
    plan.price,
    subscription.interval_unit,
  );
  const fields = [
    { field: 'Name', value: `${customer.first_name} ${customer.last_name}` },
    { field: 'Email', value: customer.email },
    {
      field: 'Credit Card Number',
      value: `XXXX XXXX XXXX ${card.last_four_digits}`,
    },
    {
      field: 'Price for one license',
      value: `$${Math.round(price * 100) / 100}`,
    },
    { field: 'Added licenses', value: mailBox - licenses },
    { field: 'Date of purchase', value: `On ${getLargeDate(new Date())}` },
    { field: 'Total', value: getMount(mailBox - licenses, price) },
  ];
  return (
    <Row className="text-center">
      <Col xs={24} md={{ span: 14, offset: 5 }}>
        <Card
          className="text-left card"
          style={{ padding: breakpoint.xs ? 1 : 20 }}
        >
          <Row justify="center" className="mb-2">
            <Col className="text-center">
              <Title level={2} style={styleTitle}>
                Order Confirmation
              </Title>
              <Title level={5} style={styleTitle}>
                Details of order:
              </Title>
            </Col>
          </Row>
          {fields.map(({ field, value }, index) => (
            <RowFields key={index} field={field} value={value} />
          ))}
          <Row justify="center" className="mt-4">
            <Col className="text-center">
              <Space direction="vertical">
                <Text>
                  Download your invoice in{' '}
                  <Link
                    href="https://payments.aeegle.com/portal/aeegle/index#/invoices"
                    target="_blank"
                  >
                    Portal
                  </Link>
                </Text>
                <Button
                  type="primary"
                  onClick={() => history.push('/checkout')}
                >
                  Accept
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default ConfirmationPayment;
