import React, { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, List, Switch, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { setGoogleAuthenticate } from '../../redux/actions/auth.action';

const { Title } = Typography;

// Props card
interface IProps {
  title: string;
  href: string;
  isFeature?: boolean;
  head: any;
  okText?: string;
  footer?: boolean;
  active?: boolean;
  onClick?: (title: string, currentMembership?: string) => void;
  buttonClick?: (title: string, currentMembership: string, e: any) => void;
  children?: React.ReactNode;
}

interface IPropsHead {
  pricing?: any;
  head?: string;
  title: string;
  image?: string;
  setCurrentTime: any;
  currentTime: string;
  onClick?: (title: string, currentMembership?: string) => void;
}

interface IPropsFooter {
  okText: string;
  href: string;
  plan: string;
  current: string;
  buttonClick?: (title: string, currentMembership: string, e: any) => void;
}

// Primary card
const CardPricing = (props: IProps) => {
  const {
    title,
    head: HeadCard,
    children,
    okText,
    href,
    footer = true,
    active = false,
    isFeature = false,
    onClick,
    buttonClick,
  } = props;
  const [currentTime, setCurrentTime] = useState('annually');
  return (
    <span>
      <Card
        onClick={() => {
          !!onClick && onClick(title, currentTime);
        }}
        className={`card__card ${!!children ? 'children' : ''}
        ${active && !children ? 'active' : ''} ${isFeature ? 'feature' : ''}`}
      >
        {isFeature && <div className="card__favorite">Most Popular</div>}
        <div className="card__pricing-title">
          <Title level={2}>{title}</Title>
        </div>
        <HeadCard
          title={title}
          onClick={onClick}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
        />
        {children}
        {footer && (
          <CardFooter
            href={href}
            plan={title}
            current={currentTime}
            okText={okText || 'ok'}
            buttonClick={buttonClick}
          />
        )}
      </Card>
    </span>
  );
};

// Head card
CardPricing.HeadPricing = ({
  pricing,
  head,
  image,
  onClick,
  title,
  setCurrentTime,
  currentTime,
}: IPropsHead) => {
  const dispatch = useDispatch();

  const handleSwitch = (checked: boolean, e: any) => {
    e.stopPropagation();
    const currentMembership = checked ? 'annually' : 'monthly';
    setCurrentTime(currentMembership);
    {
      !!onClick
        ? onClick(title, currentMembership)
        : dispatch(setGoogleAuthenticate({ currentMembership }));
    }
  };

  return (
    <div className="card__pricing-head mb-2 notranslate">
      {!!pricing[currentTime].integer || !!pricing[currentTime].decimal ? (
        <>
          <Title level={3} className="card__pricing-head-title">
            US $
            <span style={{ height: 20 }}>{pricing[currentTime].integer}</span>.
            {pricing[currentTime].decimal}
          </Title>
          <div className="mt-2">
            <Title
              level={5}
              className="text-secondary"
              style={{ fontSize: 14 }}
            >
              {currentTime === 'annually' ? 'PER MEMBER' : 'MEMBER PER MONTH'}
            </Title>
          </div>

          <div
            className="mt-4 mb-4"
            style={{ zIndex: 10000 }}
            onClick={(e) => e.stopPropagation()}
          >
            <label className="size-14">
              Monthly
              <Switch
                onChange={handleSwitch}
                checked={currentTime === 'annually'}
                defaultChecked
                className="mr-1 ml-1"
              />
              Annually
            </label>
          </div>
        </>
      ) : (
        <>
          <Title level={5} className="text-secondary" style={{ fontSize: 14 }}>
            {head}
          </Title>
          <img
            width={150}
            height={150}
            src={`${process.env.PUBLIC_URL}${image}`}
            alt=""
          />
        </>
      )}
    </div>
  );
};

// Card footer
const CardFooter = ({
  okText,
  href,
  plan,
  current,
  buttonClick,
}: IPropsFooter) => {
  const dispatch = useDispatch();
  const onClick = (e: any) => {
    if (!!buttonClick) return buttonClick(plan, current, e);
    dispatch(setGoogleAuthenticate({ plan, currentMembership: current }));
  };

  return (
    <div className="text-center card__footer pt-2">
      <Link
        replace
        to={href}
        onClick={onClick}
        style={{ position: 'absolute', bottom: 25, left: 0, right: 0 }}
      >
        <Button type="primary" size="large" className="mt-1">
          {okText}
        </Button>
      </Link>
    </div>
  );
};

// Card body list
CardPricing.CardList = ({ data }: { data: any[] }) => (
  <div>
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item style={{ justifyContent: 'center' }}>{item}</List.Item>
      )}
    />
  </div>
);

export default CardPricing;
