import { Col, Row, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { getPriceLicense } from 'utils/subscriptions';
import Licenses from './manage/Licenses';
import Payment from './manage/Payment';
import Summary from './manage/Summary';

const { Title } = Typography;
const ManagePayment = () => {
  const { subscription }: any = useStateRedux('subscription');
  const { plan, next_billing_at, interval_unit } = subscription;
  const price = getPriceLicense(next_billing_at, plan.price, interval_unit);
  return (
    <>
      <Title level={2}>Manage your Zumpul subscription</Title>
      <Row gutter={[24, 32]}>
        <Col md={14} xs={24} className="text-left">
          <Licenses price={price} />
          <Payment />
        </Col>
        <Col md={10} xs={24} className="text-left">
          <Summary price={price} />
        </Col>
      </Row>
    </>
  );
};
export default ManagePayment;
