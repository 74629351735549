// import React, { useEffect } from 'react';
import Loading from 'components/loading/loading';
import { assets } from 'helpers/assets';

const Redirect = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <img
        src={assets.zumpul_brand}
        alt="Aeegle Inc."
        style={{ height: '60px' }}
      />

      <Loading />
    </div>
  );
};

export default Redirect;
