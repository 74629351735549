import React, { useState } from 'react';
import NumberList from 'components/NumberList';
import { dataPricingSimplify } from 'utils/utils';
import SelectPlan from './components/selectPlan';

const SelectDomainPage = () => {
  const [state, setState] = useState(dataPricingSimplify);

  console.log(state);
  const handleActive = (id: number) =>
    setState(
      state.map((i: any) =>
        i.id === id ? { ...i, active: true } : { ...i, active: false },
      ),
    );

  return (
    <div
      className="animate__animated animate__fadeIn mb-5"
      style={{ minHeight: '100vh' }}
    >
      <div className="mt-2">
        <NumberList number={3} title="Plans" />
        <SelectPlan data={state} handleActive={handleActive} />
      </div>
    </div>
  );
};

export default SelectDomainPage;
