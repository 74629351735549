import axios from 'axios';
import { getStorage } from 'helpers/storage';

export const passportBaseURL = process.env.REACT_APP_PASSPORT_API;

const instance = axios.create({ baseURL: passportBaseURL });

const getToken = () => {
  const auth: any = getStorage('auth');
  if (!!auth?.google?.access_token) return auth.google.access_token;
  return '';
};

const headers = () => ({
  Authorization: `Bearer ${getToken()}`,
  Accept: 'application/json',
});

instance.interceptors.request.use(
  (req: any) => {
    if (!!req.headers['Authorization']) return req;
    req.headers = headers();
    return req;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
