import { message } from 'antd';
import { upgradeSubscription } from 'api/subscription/subscription.api';
import { getStorage, setStorage } from 'helpers/storage';

export const openMessage = (cb: () => void) => () => {
  const key = 'updatable';
  message.loading({ content: 'Loading...', key });
  upgradeSubscription()
    .then(({ data: { customer, subscription } }) => {
      message.success({
        content: 'Your subscription has been updated',
        key,
        duration: 2,
        onClose: () => cb(),
      });

      const auth = getStorage('auth');
      const sub = getStorage('subscription');
      setStorage('auth', { ...auth, ...customer });
      setStorage('subscription', {
        ...sub,
        ...subscription,
      });
    })
    .catch(() => {
      message.error('Request rejected', 6);
    });
};
