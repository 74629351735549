import { ArrowRightOutlined } from '@ant-design/icons';
import { Card, Input, Space, Typography } from 'antd';
import RowData from 'components/rowsCard/RowData';
import RowHeader from 'components/rowsCard/RowHeader';
import useStateRedux from 'hooks/useStateRedux';
import { getMount } from 'utils/subscriptions';

const { Title, Text } = Typography;
const Licenses = ({ price }: IProv) => {
  const { mailBox, licenses }: any = useStateRedux('auth');
  return (
    <>
      <Title level={5}>Licenses</Title>
      <Card className="card">
        <RowHeader list={['Item', 'N° of units to Modify', 'Total']} />
        <RowData
          list={[
            <Space direction="vertical">
              <Text strong>Additional licenses</Text>
              <Text>${Math.round(price * 100) / 100}/ License</Text>
            </Space>,
            <Input value={mailBox - licenses} readOnly />,
            getMount(mailBox - licenses, price),
          ]}
        />
        <RowData
          list={[
            'upgrade',
            <Text type="success">
              {licenses}
              <ArrowRightOutlined />
              {mailBox}
            </Text>,
            // getMount(mailBox, price),
          ]}
          colorType={1}
          indexsApply={[0, 1]}
          dividerOut
        />
      </Card>
    </>
  );
};
interface IProv {
  price: number;
}
export default Licenses;
