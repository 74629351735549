import { Col, Row } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import PaymentInfo from './PaymentInfo';
import PaymentMethod from './PaymentMethod';
import SubscritionDetailts from './SubscriptionDetails';

const PlanInfo = ({ callback }: IProv) => {
  const { subscription }: any = useStateRedux('subscription');
  const { edition }: any = useStateRedux('auth');
  const TRIAL = edition === 'TRIAL' || edition === 'EXPIRED';
  const { next_billing_at, last_billing_at } = subscription;
  return (
    <div>
      <Row gutter={[24, 32]}>
        {TRIAL ? (
          <Col md={{ span: 12, offset: 6 }} xs={24}>
            <SubscritionDetailts callback={callback} />
          </Col>
        ) : (
          <>
            <Col md={14} xs={24} className="text-left">
              <SubscritionDetailts callback={callback} />
            </Col>
            <Col md={10} xs={24} className="text-left">
              <PaymentInfo date={next_billing_at} />
              <PaymentInfo date={last_billing_at} last />
              <PaymentMethod />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
interface IProv {
  callback: () => void;
}
export default PlanInfo;
