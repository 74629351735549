import React from 'react';
import {
  FacebookFilled,
  LinkedinFilled,
  TwitterCircleFilled,
} from '@ant-design/icons';
import { Space } from 'antd';

const styles: any = {
  socialIcon: {
    fontSize: 25,
    color: '#b0b2b7',
  },
};

// https://es-la.facebook.com/AeegleCloud/
// https://sv.linkedin.com/company/aeegle
// https://twitter.com/aeegle

const SocialMedia = () => {
  return (
    <Space className="social-media">
      <a
        href="https://sv.linkedin.com/company/aeegle"
        rel="noreferrer noopener"
        target="_blank"
      >
        <LinkedinFilled className="icon-hover" style={styles.socialIcon} />
      </a>
      <a
        href="https://es-la.facebook.com/AeegleCloud"
        rel="noreferrer noopener"
        target="_blank"
      >
        <FacebookFilled className="icon-hover" style={styles.socialIcon} />
      </a>
      <a
        href="https://twitter.com/aeegle"
        rel="noreferrer noopener"
        target="_blank"
      >
        <TwitterCircleFilled className="icon-hover" style={styles.socialIcon} />
      </a>
    </Space>
  );
};

export default SocialMedia;
