import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ISuit } from 'models/api/auth.model';
import { initialSuitModel } from '../initialStates';
import axios from 'config/axios/v1Passport.instance';

export default function useFetchSuit() {
  const { google } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<ISuit>(initialSuitModel);
  const [state, setState] = useState({ loading: true, error: null });

  useEffect(() => {
    if (!!google.access_token) {
      axios
        .post('/auth/verify', { access_token: google.access_token })
        .then(({ data }) => {
          setData(data);
          setState({ loading: false, error: null });
        })
        .catch((e) =>
          setState({ error: e?.message || e?.response?.data, loading: false }),
        );
    }
  }, [google.access_token]);

  return { data, ...state };
}
