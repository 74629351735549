import { Col, Divider, Grid, Row, Typography } from 'antd';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;
const RowFields = ({ value, field }: IProv) => {
  const breakpoint = useBreakpoint();
  return (
    <>
      <Row style={{ minHeight: 34 }}>
        <Col span={12} className={breakpoint.xs ? 'pl-1' : 'pl-3'}>
          <Row justify="space-between" style={{ height: '100%' }}>
            <Text strong ellipsis>
              {field}
            </Text>
            <Divider type="vertical" style={{ height: 'auto' }} />
          </Row>
        </Col>
        <Col span={12} className={breakpoint.xs ? 'pl-1' : 'pl-3'}>
          {field == 'Total' ? (
            <Title level={5}>{value}</Title>
          ) : (
            <Text>{value}</Text>
          )}
        </Col>
      </Row>
      <Divider style={{ margin: 0 }} />
    </>
  );
};
interface IProv {
  field: string;
  value: string;
}
export default RowFields;
