import logout from 'config/google/logout';
import { scopes } from 'config/google/scopes';
import Redirect from 'pages/error/redirect';
import { useEffect } from 'react';
import { useGoogleApi } from 'react-gapi';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const signOut = () => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth) {
      logout(auth, history, dispatch);
    }
  }, [auth]);
  return <Redirect></Redirect>;
};
export default signOut;
