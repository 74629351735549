import { Typography } from 'antd';
import { assets } from 'helpers/assets';
import { useDispatch } from 'react-redux';
import { useGoogleApi } from 'react-gapi';
import actionLogin from 'utils/actionLogin';
import AuthForm from './components/authForm';
import { scopes } from 'config/google/scopes';
import Footer from 'components/footer/footer';
import { useHistory } from 'react-router-dom';
import MobileWrapper from './components/mobileWrapper';
import { loginAuth } from 'api/authAndCustomer/login.api';
import { setAuthenticate } from 'redux/actions/auth.action';
import VideoIntroduction from './components/videoIntroduction';
import DesktopWrapper from './components/DesktopWrapper';
import { getCurrentMembership, getPlan } from 'utils/getCurrentPayment';

const { Title } = Typography;

const LoginPage = () => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes: scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const history = useHistory();
  const dispatch = useDispatch();
  const onFinish = async (profile: any) => {
    const { access_token, ...rest } = profile;
    const { data, err } = await loginAuth({ access_token });
    if (!!err) {
      return actionLogin({ ...err, profile, history, dispatch, auth });
    }

    dispatch(
      setAuthenticate({
        ...data,
        ...rest,
        authenticated: true,
        loading: false,
        google: {
          access_token,
          plan: getPlan(data.edition),
          currentMembership: getCurrentMembership(data.edition),
        },
      }),
    );
    return history.push('/redirect');
  };

  return (
    <div
      style={{ overflowY: 'scroll', height: '100vh' }}
      className="beauty-scroll"
    >
      <div className="auth__content animate__animated animate__fadeIn">
        <DesktopWrapper />
        <MobileWrapper />
        <div className="auth__wrapper_login">
          <div className="auth__info animate__animated animate__fadeIn">
            <div style={{ textAlign: 'center' }}>
              <Title level={1}>Zumpul email signatures for G Suite</Title>
              <Title level={3}>
                Marketing & Compliance on your G Suite email signatures.
              </Title>
              <AuthForm onFinish={onFinish} />
            </div>
          </div>
          <div
            className="auth__login-content animate__animated animate__fadeInRight"
            style={{ backgroundImage: `url('${assets.zumpul_bg_auth}')` }}
          >
            <VideoIntroduction />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
