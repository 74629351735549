const listEmployers: string[] = [
  '1 - 49',
  '50 - 99',
  '100 - 499',
  '500 - 999',
  '1,000 - 4,999',
  '5,000 - 9,999',
];
const typeGoogle = [
  'G Suite',
  'G Suite Monthly Payment',
  'G Suite Business',
  'G Suite Enterprise',
  'G Suite for Education',
  'G Suite Monthly Payment (No Reseller)',
  'Other',
];

export const contactFields: any = [
  {
    label: 'First Name',
    required: true,
    name: 'first_name',
    rules: [{ required: true, message: 'First name is required' }],
    placeHolder: 'John',
  },
  {
    label: 'Last Name',
    required: true,
    name: 'last_name',
    rules: [{ required: true, message: 'Last name is required' }],
    placeHolder: 'Harrison',
  },
  {
    label: 'Email',
    required: false,
    name: 'admin_email',
    rules: [
      { required: true, message: 'Email is required' },
      { type: 'email', meesage: 'input a email valid' },
    ],
    placeHolder: 'example@mail.com',
  },
  {
    label: 'Phone',
    required: true,
    name: 'phone',
    rules: [{ required: true, message: 'Phone is required' }],
    phone: true,
  },
  {
    label: 'Country',
    required: true,
    name: 'country',
    country: true,
  },
  {
    label: 'Job title',
    required: true,
    name: 'job_title',
    rules: [
      {
        required: true,
        message: 'Job title  is required',
      },
    ],
    placeHolder: 'Manager',
  },
];

export const companyFields = [
  {
    label: 'Company',
    required: true,
    name: 'summary',
    rules: [{ required: true }],
    placeHolder: 'Aeegle - Zumpul',
  },
  {
    label: 'Number of employers',
    required: true,
    name: 'number_of_employees',
    rules: [{ required: true, message: 'Number of employers is required' }],
    select: true,
    list: listEmployers,
  },
  {
    label: 'Type of Google App',
    required: true,
    name: 'type_gaps',
    rules: [{ required: true, message: 'Type of Google App is required' }],
    select: true,
    list: typeGoogle,
    block: true,
  },
];
