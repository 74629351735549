import { useSelector } from 'react-redux';
import { IAuthModel, IReduxState, IUIModel } from 'models/global.model';
import { ITemplateFormModel } from '../models/form.model';
import { ISubscriptionModel } from 'models/subscription.model';

type Action =
  | IReduxState
  | IAuthModel
  | IUIModel
  | ITemplateFormModel
  | ISubscriptionModel;

const useStateRedux = (type?: 'auth' | 'ui' | 'forms' | 'subscription') => {
  const state: any = useSelector((state) => state);
  const data: Action = !!type ? state[type] : state;
  return data;
};

export default useStateRedux;
