import React from 'react';

const buttonHead: (color: string, index: number) => React.CSSProperties = (
  color,
  index,
) => ({
  position: 'absolute',
  top: 6,
  left: index * 18,
  width: 12,
  height: 12,
  borderRadius: '100%',
  background: color,
});

const VideoIntroduction = ({ mobile }: IProps) => {
  return (
    <div
      className={`media_iframe_video ${!!mobile ? 'mobile' : ''}`}
      data-oe-expression="//www.youtube.com/embed/1m-Q_1eMiMM?autoplay=0&amp;rel=0&amp;loop=1"
    >
      <span style={buttonHead('#FF5F57', 1)} />
      <span style={buttonHead('#FEBC2E', 2)} />
      <span style={buttonHead('#28C840', 3)} />
      <iframe
        width="100%"
        height="250px"
        src="//www.youtube.com/embed/1m-Q_1eMiMM?autoplay=0&amp;rel=0&amp;loop=1"
        frameBorder="0"
      />
    </div>
  );
};

interface IProps {
  mobile?: boolean;
}

export default VideoIntroduction;
