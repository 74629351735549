import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Steps, Tooltip, Grid } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setStep } from 'redux/actions/step.action';
const { Step } = Steps;
const { useBreakpoint } = Grid;
const HeaderPayment = () => {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const { step } = useSelector((state: any) => state.step);
  const dispatch = useDispatch();
  useEffect(() => {
    const [, , route] = history.location.pathname.split('/');
    switch (route) {
      case 'confirm':
        dispatch(setStep(1));
        break;
      case 'confirmation':
        dispatch(setStep(2));
        break;
      default:
        dispatch(setStep(0));
        break;
    }
  }, [history]);
  const changeStep = (current: number) => {
    dispatch(setStep(current));
    switch (current) {
      case 0:
        history.push('/payment/manage');
        break;
      case 1:
        history.push('/payment/confirm');
        break;
      case 2:
        history.push('/payment/confirmation');
        break;
      default:
        history.push('/payment');
        break;
    }
  };
  return (
    <Row
      className="text-start"
      style={{ display: breakpoint.xs ? 'none' : 'flex' }}
    >
      <Tooltip title="back">
        <Button
          shape="circle"
          icon={<LeftOutlined />}
          style={{ background: '#D2EAFF' }}
          onClick={() => history.push('/checkout')}
        />
      </Tooltip>
      <Col span={12} offset={6}>
        <Steps current={step} onChange={changeStep} type="navigation">
          <Step title="Step 1" description="Manage Licenses" />
          <Step title="Step 2" description="Confirm Order" />
          <Step title="Step 3" description="Confirmation" />
        </Steps>
      </Col>
    </Row>
  );
};

export default HeaderPayment;
