import { Typography } from 'antd';
import React from 'react';
import Navbar from 'components/navbar/navbar';
import { dataPricingSimplify } from 'utils/utils';
import SelectPlan from 'pages/completeConfig/components/selectPlan';

const PricingPage = () => {
  return (
    <div className="dashboard___wrapper-main-content ">
      <div className="dashboard__body bg-content">
        <Navbar brand />
        <div className="dashboard__content dashboard__content-padding beauty-scroll">
          <div
            className="animate__animated animate__fadeIn text-center"
            style={{ minHeight: '100vh' }}
          >
            <Typography.Title level={1}>Zumpul plan for you!</Typography.Title>
            <div>
              <SelectPlan data={dataPricingSimplify} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
