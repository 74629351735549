import { Card, Row, Typography } from 'antd';
import RowData from 'components/rowsCard/RowData';
import RowHeader from 'components/rowsCard/RowHeader';
import useStateRedux from 'hooks/useStateRedux';
import { getLargeDate, getLongDay, getMount } from 'utils/subscriptions';

const { Title, Link } = Typography;
const PaymentInfo = ({ date, last }: IProps) => {
  const { mailBox }: any = useStateRedux('auth');
  const { subscription, invoices }: any = useStateRedux('subscription');
  const { plan } = subscription;

  const lastInvoice = last
    ? invoices.find((x: any) => x.invoice_date == date)
    : null;
  return (
    <div style={{ marginBottom: 10 }}>
      <Row justify="space-between">
        <Title level={5}>{last ? 'Last' : 'Next'} Payment</Title>
        {!last ? (
          <Link
            href="https://payments.aeegle.com/portal/aeegle/"
            target="_blank"
          >
            payment history
          </Link>
        ) : null}
      </Row>

      <Card className="card">
        <RowHeader list={['Date', 'Day', 'Total']} />
        <RowData
          list={[
            `On ${getLargeDate(date)}`,
            getLongDay(date),
            last ? `$${lastInvoice?.total}` : getMount(mailBox, plan.price),
          ]}
          small
        />
      </Card>
    </div>
  );
};
interface IProps {
  date: string;
  last?: boolean;
}
export default PaymentInfo;
