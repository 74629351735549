export const transformGoogleProfile = (profile: any) => ({
  displayName: `${profile.getName()} ${profile.getFamilyName()}`,
  image: profile.getImageUrl(),
  google_id: profile.getId(),
  email: profile.getEmail(),
});

export const hideEntitiesOfData = (data: any[], entities: any[]) => {
  return data.filter((i) => {
    if (!entities.find(({ id }) => id === i.id)) return i;
  });
};

export const abbreviateNumber = (number: number) => {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier == 0) return number;
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1) + suffix;
};
const sanitizeExpCard = (number: number) => {
  const num = number.toString();
  return num.length == 1 ? `0${num}` : num.substring(2, 4);
};
export const getExpCard = (card: any) =>
  `${sanitizeExpCard(card.expiry_month)}/${sanitizeExpCard(card.expiry_year)}`;
