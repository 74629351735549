import React from 'react';
import { Col, Row } from 'antd';
import { assets } from '../../helpers/assets';
import SocialMedia from '../social/socialMedia';
import Info from './info';

interface IProps {
  [key: string]: React.CSSProperties | any;
}

const styles: IProps = {
  created: { color: '#444444', fontWeight: 500, fontSize: '12px' },
  colBrand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: 'auto',
  },
  colMediaSocial: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  socialIcon: {
    fontSize: 25,
    color: '#b0b2b7',
  },
};

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={6} style={styles.colBrand}>
          <div style={{ textAlign: 'center' }}>
            <img src={assets.aeegle_icon} alt="Aeegle" width={160} />
          </div>
          <div className="footer__show-575">
            <SocialMedia />
          </div>
        </Col>
        <Col xs={24} sm={18} style={styles.colMediaSocial}>
          <div className="footer__align">
            <SocialMedia />
            <Info />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
