import axios from 'axios';
import { getStorage } from 'helpers/storage';
import { passportBaseURL } from 'config/axios/v1Passport.instance';

const getToken = () => {
  const auth: any = getStorage('auth');
  if (!!auth?.token) return auth.token;
  return '';
};

export const headers = () => ({
  Authorization: `Bearer ${getToken()}`,
  Accept: 'application/json',
});

export const getRefreshToken = async () => {
  const auth: any = getStorage('auth');
  if (!!auth.token) {
    console.log('refreshing');
    return axios
      .post(
        `${passportBaseURL}/auth/refresh-token`,
        {
          refresh_token: auth.refresh_token,
        },
        { headers: headers() },
      )
      .then(({ data }) => {
        localStorage.setItem('auth', JSON.stringify({ ...auth, ...data }));
        return data.token;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
};
