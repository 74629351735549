import { MailFilled, PlayCircleFilled } from '@ant-design/icons';
import { Button, Grid, Row, Space, Typography } from 'antd';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Step = ({ first = true, callback }: IProv) => {
  const breakpoint = useBreakpoint();
  const texts = [
    'Add Zumpul from the Google Workspace Marketplace',
    'Syncronize Data and choose a plan',
  ];
  return (
    <>
      <Row justify={breakpoint.xs ? 'start' : 'center'}>
        <Title style={{ fontSize: 128, margin: 0 }}>{first ? '1' : '2'}</Title>
        <div
          style={{
            display: 'grid',
            maxWidth: breakpoint.xs ? '70%' : '30%',
            textAlign: 'start',
            alignContent: 'center',
          }}
        >
          <Title level={2} style={{ margin: 0 }}>
            Step
          </Title>
          <Text>{texts[first ? 0 : 1]}</Text>
        </div>
      </Row>
      <Row justify="center" align="middle" color="yellow">
        <Space>
          {first ? (
            <>
              <Button type="primary" size="large">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://gsuite.google.com/marketplace/app/zumpul_email_signature_marketing_tool/394696046694"
                >
                  Get in Marketplace
                </a>
              </Button>
              <PlayCircleFilled className="icon_custom" />
            </>
          ) : (
            <>
              <Button
                size="large"
                style={{ borderStyle: 'double' }}
                onClick={callback}
              >
                Login account
              </Button>
              <MailFilled className="icon_custom" />
            </>
          )}
        </Space>
      </Row>
    </>
  );
};
interface IProv {
  first?: boolean;
  callback?: () => void;
}
export default Step;
