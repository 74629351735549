import React from 'react';
import { IUIModel } from 'models/auth.model';
import {
  DashboardOutlined,
  // DisconnectOutlined,
  FontSizeOutlined,
  FormOutlined,
  // ShareAltOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

const UiInitialState: IUIModel = {
  aside: {
    open: true,
    animationAside: false,
    isMobile: false,
    selectedKeys: ['dashboard'],
    openKeys: [],
  },
  loading: false,
  optionalLoading: false,
  dashboard: [
    {
      id: 'dashboard',
      name: 'Dashboard',
      href: '/',
      icon: <DashboardOutlined />,
      active: false,
    },
    {
      id: 'signature',
      name: 'Signature',
      icon: <FormOutlined />,
      active: false,
      subMenu: [
        {
          id: 'signature-list',
          name: 'Signatures',
          icon: <FormOutlined />,
          href: '/signatureList',
          active: false,
        },
        {
          id: 'signature-template',
          name: 'Template',
          href: '/signatureTemplateList',
          icon: <FontSizeOutlined />,
          active: false,
        },
      ],
    },
    {
      id: 'people',
      name: 'People',
      icon: <UserOutlined />,
      active: false,
      subMenu: [
        {
          id: 'people-list',
          name: 'People',
          href: '/entity/person',
          icon: <TeamOutlined />,
          active: false,
        },
        // {
        //   id: 'out-office',
        //   name: 'Out Office',
        //   href: '/out-office',
        //   icon: <DisconnectOutlined />,
        //   active: false,
        // },
        // {
        //   id: 'people-shared-contact',
        //   name: 'Shared Contacts',
        //   icon: <ShareAltOutlined />,
        //   href: '/sharedContactsList',
        //   active: false,
        // },
      ],
    },
  ],
};

export default UiInitialState;
