import React from 'react';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import { Redirect, Route } from 'react-router-dom';
import { getStorage } from 'helpers/storage';

interface IProps {
  component: any;
  path: string;
  isAdmin?: boolean;
  preCheck?: boolean;

  [key: string]: unknown;
}

const PrivateGoogleRoute = ({
  component: Component,
  isAdmin = true,
  preCheck = false,
  ...rest
}: IProps) => {
  const { google, loading, token }: any = useStateRedux('auth');
  const sub: any = getStorage('subscription');
  if (loading) return <Loading />;
  if (!!token && isAdmin) return <Redirect to="/login" />;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        !preCheck ? (
          google.access_token ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        ) : sub?.authorized ? (
          <Component {...props} />
        ) : (
          <Redirect to="/checkout" />
        )
      }
    />
  );
};

export default PrivateGoogleRoute;
