import React from 'react';
import { Divider, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import { useGoogleApi } from 'react-gapi';
import logout from 'config/google/logout';
import { scopes } from 'config/google/scopes';
import useStateRedux from 'hooks/useStateRedux';
import { Link, useHistory } from 'react-router-dom';
import { CheckCircleFilled, SettingOutlined } from '@ant-design/icons';

const menu = (brand?: boolean) => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user_full_name, email }: any = useStateRedux('auth');

  const onSingOut = () => {
    return logout(auth, history, dispatch);
  };
  const items = [
    {
      key: 0,
      label: (
        <>
          <p>
            <b>{user_full_name}</b>
          </p>
          <p>{email}</p>
        </>
      ),
    },
    { key: 3, label: <Divider style={{ margin: 0 }} /> },
    {
      key: 4,
      label: (
        <div className="text-center">
          <span className="text-danger">Sign out</span>
        </div>
      ),
      onClick: onSingOut,
    },
  ];
  if (!brand) {
    const item1 = {
      key: 1,
      label: (
        <Link to="/setting">
          <SettingOutlined style={{ color: '#72757a' }} /> <span>Settings</span>
        </Link>
      ),
    };
    const item2 = {
      key: 2,
      label: (
        <Link to="/accountProfile">
          <CheckCircleFilled style={{ color: '#72757a' }} />{' '}
          <span>Subscription</span>
        </Link>
      ),
    };
    items.push(item1);
    items.push(item2);
  }
  return <Menu className="aside__menu-menu" items={items}></Menu>;
};

export default menu;
