import { resetAuth } from 'redux/actions/auth.action';

export default async function logout(
  auth: any,
  history: any,
  dispatch: any,
  redirect = true,
) {
  try {
    await auth.signOut();
  } catch (e) {
    console.log(e);
  }
  if (redirect) {
    dispatch(resetAuth());
    localStorage.removeItem('auth');
    localStorage.removeItem('subscription');

    // Parse query string
    const query = new URLSearchParams(location.search);
    const return_url = query.get('return_url');

    if (return_url) {
      const queryParams = new URLSearchParams({
        redirect_url: return_url,
      }).toString();
      history.push(`/login?${queryParams}`);
      return;
    }
    history.push('/login');
  }
}
