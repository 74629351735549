import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import RowData from 'components/rowsCard/RowData';
import RowHeader from 'components/rowsCard/RowHeader';
import useStateRedux from 'hooks/useStateRedux';
import { getPlan } from 'utils/getCurrentPayment';
import { getMount } from 'utils/subscriptions';
const { Title, Text } = Typography;

const SubscritionDetailts = ({ callback }: IProv) => {
  const { edition, licenses, mailBox }: any = useStateRedux('auth');
  const { subscription }: any = useStateRedux('subscription');
  const { plan } = subscription;
  const { price } = plan;
  const diff = licenses - mailBox;
  const type = diff < 0 ? -1 : diff > 0 ? 1 : 0;
  const TRIAL = edition === 'TRIAL' || edition === 'EXPIRED';
  return (
    <div>
      <Title level={5}>Subscription details</Title>
      <Card className="card">
        <RowHeader list={['Item', 'Description/Unit', 'Amount']} />
        <RowData list={['Edition', edition, `$${price} PU`]} />
        <RowData list={['Licences', licenses, getMount(licenses, price)]} />
        <RowData list={['Mail Boxes', mailBox, getMount(mailBox, price)]} />
        <RowData
          list={['Licences Status', diff, getMount(diff, price)]}
          colorType={type}
          indexsApply={[1, 2]}
        />
        <Divider
          orientation="left"
          orientationMargin={0}
          style={{ color: type == -1 ? 'red' : type == 1 ? '#099409' : '' }}
        >
          {type == -1
            ? 'You have unpaid licenses'
            : type == 1
            ? 'You have licenses avaliable'
            : ''}
        </Divider>
        <Row justify={TRIAL ? 'center' : 'end'}>
          {TRIAL ? (
            <Space direction="vertical" className="mt-3" size="small">
              <Title level={5} style={{ margin: 0 }}>
                With the PRO version you get
              </Title>
              <Space direction="vertical" size={1}>
                <Text>create and use to 20 signature templates</Text>
                <Text>Apply the signatures unlimited times in a mounth</Text>
              </Space>
              <Col className="mt-2">
                <Button type="primary" onClick={callback}>
                  Get Zumpul PRO
                </Button>
              </Col>
            </Space>
          ) : (
            <Title level={4}>
              Version {getPlan(edition).toLocaleUpperCase()}
            </Title>
          )}
        </Row>
      </Card>
    </div>
  );
};
interface IProv {
  callback: () => void;
}
export default SubscritionDetailts;
