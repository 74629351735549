import { abbreviateNumber } from './transform';

export const getMount = (size: number, price: number) => {
  const num: any = Math.round(size * price * 100) / 100;
  const prefix = num < 0 ? '-' : '';
  const abr = abbreviateNumber(num);
  return prefix.concat(`$${abr.toString().replaceAll('-', '')}`);
};

const parseDate = (date: string) => {
  const split = date.split('-').map((x) => parseInt(x));
  return new Date(split[0], split[1] - 1, split[2]);
};

export const getLargeDate = (date: string | Date) =>
  (typeof date == 'string' ? parseDate(date) : date).toLocaleString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const getLongDay = (date: string) =>
  parseDate(date).toLocaleString('en-us', { weekday: 'long' });

const getDays = () => {
  const year = new Date().getFullYear();
  const first = new Date(year, 0, 1, 0, 0, 0).getTime();
  const second = new Date(year, 11, 31, 24, 23, 59).getTime();
  const diff = second - first;
  return Math.round(diff / (1000 * 60 * 60 * 24));
};

export const getPriceLicense = (
  nextBilling: string,
  price: number,
  typePlan: 'years' | 'months',
) => {
  if (typePlan === 'months') return 0.0;
  const nextDate = parseDate(nextBilling);
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  const diff = nextDate.getTime() - now.getTime();
  const days = diff / (1000 * 60 * 60 * 24);
  const licensePrice = (price / getDays()) * days;
  return licensePrice;
};
