import { Select } from 'antd';

const CountryInput = ({ countries, ...rest }: IProv) => {
  return (
    <Select
      showSearch
      size="large"
      optionFilterProp="children"
      placeholder="Select an option"
      dropdownStyle={{ backgroundColor: '#EBEBEB' }}
      {...rest}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {countries.map((i: any) => (
        <Select.Option
          key={i.value}
          value={JSON.stringify({
            country_name: i.label,
            country_code: i.value.toLowerCase(),
          })}
        >
          {i.label}
        </Select.Option>
      ))}
    </Select>
  );
};
interface IProv {
  countries: any[];

  [key: string]: any;
}
export default CountryInput;
