import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setStep } from 'redux/actions/step.action';
import { getPlan } from 'utils/getCurrentPayment';
import { getMount } from 'utils/subscriptions';

const { Title, Text } = Typography;
const Summary = ({ price }: IProv) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { mailBox, edition, licenses }: any = useStateRedux('auth');

  return (
    <>
      <Title level={5}>Order Summary</Title>
      <Card className="card" style={{ paddingRight: 20 }}>
        <Row style={{ marginTop: 40 }}>
          <Col span={20}>
            <Space direction="vertical">
              <Text type="success">Upgrade licenses</Text>
              <Text>Licenses</Text>
            </Space>
          </Col>
          <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space direction="vertical">
              <Text type="success">+{mailBox - licenses}</Text>
              <Text>${Math.round(price * 100) / 100}</Text>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={20}>
            <Text>Subtotal</Text>
          </Col>
          <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Text>{getMount(mailBox - licenses, price)}</Text>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }} justify="space-between">
          <Col span={12}>
            <Title level={5}>
              Version {getPlan(edition).toLocaleUpperCase()}
            </Title>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                dispatch(setStep(1));
                history.push('/payment/confirm');
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};
interface IProv {
  price: number;
}
export default Summary;
