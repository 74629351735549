import React from 'react';
import AppRoutes from 'routes';
import { store } from 'redux/stores/store';
import { GoogleApiProvider } from 'react-gapi';
import { Provider as ProviderRedux } from 'react-redux';

function App() {
  return (
    // @ts-ignore
    <GoogleApiProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ProviderRedux store={store}>
        <AppRoutes />
      </ProviderRedux>
    </GoogleApiProvider>
  );
}

export default App;
