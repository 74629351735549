import React from 'react';

const NumberList = ({ number, title }: IProps) => {
  return (
    <div className="number-list">
      <div className="number">{number}</div>
      <span>{title}</span>
    </div>
  );
};

interface IProps {
  number: number | React.ReactNode | string;
  title: string;
}

export default NumberList;
