import React from 'react';
import { assets } from 'helpers/assets';

const DesktopWrapper = () => {
  return (
    <div
      className="auth__form-zumpul"
      style={{
        backgroundImage: `url('${assets.zumpul_img}')`,
      }}
    />
  );
};

export default DesktopWrapper;
