export const assets = {
  electronic_img: `${process.env.PUBLIC_URL}/bg-electronic.png`,
  zumpul_brand: `${process.env.PUBLIC_URL}/brand2.svg`,
  zumpul_img: `${process.env.PUBLIC_URL}/zumpul_logo.svg`,
  zumpul_img2: `${process.env.PUBLIC_URL}/brand2.svg`,
  zumpul_img3: `${process.env.PUBLIC_URL}/zumpul_img.png`,
  zumpul_bg_auth: `${process.env.PUBLIC_URL}/bg-auth2.svg`,
  google_icon: `${process.env.PUBLIC_URL}/google_icon.svg`,
  aeegle_icon: `${process.env.PUBLIC_URL}/aeegle.png`,
  success: `${process.env.PUBLIC_URL}/success.png`,
  banner1: `${process.env.PUBLIC_URL}/banner1.png`,
  banner2: `${process.env.PUBLIC_URL}/banner2.png`,
  paypal: `${process.env.PUBLIC_URL}/paypal.png`,
  account: `${process.env.PUBLIC_URL}/account.png`,
  icon_account: `${process.env.PUBLIC_URL}/icon_account.png`,
};
