import { Col, Grid, Row, Typography } from 'antd';
import { style } from './RowData';
const { Title } = Typography;
const { useBreakpoint } = Grid;
const RowHeader = ({ list }: IProps) => {
  const breakpoint = useBreakpoint();
  return (
    <Row wrap={false}>
      {list.map((x, index) => (
        <Col style={style(index)} span={8} key={index}>
          <Title level={breakpoint.xs ? 5 : 4} ellipsis>
            {x}
          </Title>
        </Col>
      ))}
    </Row>
  );
};
interface IProps {
  list: Array<any>;
}
export default RowHeader;
