import React, { useEffect, useState } from 'react';
import { useGoogleApi } from 'react-gapi';
import { scopes } from 'config/google/scopes';
import { transformGoogleProfile } from 'utils/transform';
import ButtonGoogle from 'components/buttons/buttonGoogle';

interface IProps {
  onFinish: (access_token: any) => void;
}

const AuthForm = ({ onFinish }: IProps) => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes: scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const [loading, setLoading] = useState(false);
  const isSignedIn = auth?.isSignedIn.get();

  const signIn = async () => {
    setLoading(true);
    try {
      await auth.signIn();
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      const data = auth?.currentUser.get();
      const profile = transformGoogleProfile(data.getBasicProfile());
      const { access_token } = data.getAuthResponse();
      if (!!access_token) {
        onFinish({ access_token, ...profile });
      }
    }
  }, [isSignedIn]);

  return (
    <div>
      <ButtonGoogle loading={!auth || loading || isSignedIn} onClick={signIn} />
    </div>
  );
};

export default AuthForm;
