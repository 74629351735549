import Loading from 'components/loading/loading';
import { useState } from 'react';
import HeaderPayment from './components/HeaderPayment';
import RoutesPayment from './components/Routes';

const PaymentPage = () => {
  const [loading] = useState(false);
  return (
    <div className="animate__animated animate__fadeIn text-center">
      {loading ? (
        <Loading />
      ) : (
        <div className="container">
          <HeaderPayment />
          <div style={{ marginTop: 15 }}>
            <RoutesPayment />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
