import axios from 'config/axios/v1Passport.instance';
// const hostZumpul =
//   process.env.NODE_ENV == 'development'
//     ? 'http://localhost:9000'
//     : 'http://z.aeegle.com';
// export const loginAuth = async (body: any) => {
//   return await axios
//     .post('/auth/login', body)
//     .then(({ data }: any) => {
//       const { user_email, customer_id } = data;
//       const redirect = JSON.stringify({
//         email: user_email,
//         customerId: customer_id,
//       });
//       const encoded = btoa(redirect);
//       window.location.href = `${hostZumpul}/loginExternal/${encoded}`;
//       return { err: false, data };
//     })
//     .catch((err) => ({
//       err: err?.response?.data || { code: 'FAIL', name: err?.message },
//       data: null,
//     }));
// };

const hostZumpul =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost:3000'
    : 'http://z.aeegle.com';

export const loginAuth = async (body: any) => {
  return await axios
    .post('/auth/login', body)
    .then(({ data }: any) => {
      // New app
      const { access_token } = body;

      const queryParams = new URLSearchParams({
        accessToken: access_token,
      }).toString();

      const query = new URLSearchParams(location.search);
      const redirect_url = query.get('redirect_url');

      if (query.get('redirect_url')) {
        const url = decodeURIComponent(redirect_url!);
        window.location.href = `${url}?${queryParams}`;
        return { err: false, data };
      }

      // Legacy app
      const { user_email, customer_id } = data;
      const redirect = JSON.stringify({
        email: user_email,
        customerId: customer_id,
      });
      const encoded = btoa(redirect);

      if (process.env.NODE_ENV == 'development') {
        window.location.href = `${hostZumpul}/auth/callback/google?${queryParams}`;
      } else {
        window.location.href = `${hostZumpul}/loginExternal/${encoded}`;
      }
      return { err: false, data };
    })
    .catch((err) => ({
      err: err?.response?.data || { code: 'FAIL', name: err?.message },
      data: null,
    }));
};
