import React from 'react';
import Navbar from 'components/navbar/navbar';
import { assets } from 'helpers/assets';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;
export const ProviderUerRoles = ({
  children,
  image = 0,
  avatar = true,
}: IProv) => {
  const breakpoint = useBreakpoint();
  return (
    <div className="dashboard___wrapper-main-content ">
      <div className="dashboard__body bg-content">
        <Navbar brand avatar={avatar} />
        <div
          className="dashboard__content beauty-scroll"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom left',
            backgroundSize: breakpoint.xs
              ? image == 1
                ? '100% auto'
                : '100% auto'
              : image == 1
              ? 'auto 85%'
              : '100% auto',
            backgroundImage: `url('${
              image == 1 ? assets.banner2 : image == 0 ? assets.banner1 : ''
            }')`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
interface IProv {
  children: React.ReactNode;
  image?: number;
  avatar?: boolean;
}
export default ProviderUerRoles;
