import { ITypeAuthAction } from 'models/global.model';
import { type } from '../types/index.type';

const init = {
  step: 0,
};

const StepReducer = (state: typeof init = init, action: ITypeAuthAction) => {
  switch (action.type) {
    case type.stepSet:
      return { ...action.payload };
    default:
      return state;
  }
};

export default StepReducer;
