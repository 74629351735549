import { Redirect, Route, Switch } from 'react-router-dom';
import ConfirmationPayment from './ConfirmationPayment';
import ConfirmPayment from './ConfirmPayment';
import ManagePayment from './ManagePayment';

const RoutesPayment = () => {
  const routes = [
    { path: '/payment/manage', component: ManagePayment },
    { path: '/payment/confirm', component: ConfirmPayment },
    { path: '/payment/confirmation', component: ConfirmationPayment },
  ];
  return (
    <Switch>
      {routes.map((x, i) => (
        <Route key={i} path={x.path} component={x.component} />
      ))}
      <Redirect to="/payment/manage" />
    </Switch>
  );
};
export default RoutesPayment;
