import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { getExpCard } from 'utils/transform';

const { Title, Text } = Typography;
const Payment = () => {
  const { subscription }: any = useStateRedux('subscription');
  const { card } = subscription;
  return (
    <>
      <Card className="card" style={{ marginTop: 20 }}>
        <Row>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Title level={4}>Payment</Title>
          </Col>
          <Divider style={{ margin: 10 }} />
          <Space direction="vertical">
            <Title level={5}>Credit card number</Title>
            <Text>{`XXXX XXXX XXXX ${card.last_four_digits}`}</Text>
            <Text>Card Expires on {getExpCard(card)}</Text>
            <Text>Gateway - {card.payment_gateway}</Text>
            <Text>Funding - {card.funding}</Text>
          </Space>
        </Row>
      </Card>
    </>
  );
};
export default Payment;
