import { useMedia } from 'hooks/useMedia';
import NotAdmin from 'pages/error/notAdmin';
import PrivateRoute from './utils/router.private';
import { useEffect, useState } from 'react';
import AuthRoutes from './publicRoutes/authRoutes';
import { setAuth } from 'redux/actions/auth.action';
import PricingPage from 'pages/pricing/pricingPage';
import { useDispatch, useSelector } from 'react-redux';
import { handleIsMobile } from 'redux/actions/ui.action';
import NotInstallation from 'pages/error/notInstallation';
import PrivateGoogleRoute from './utils/accessRouter.private';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import SuccessInstallation from 'components/successInstallation';
import {
  CheckoutPage,
  NotAccess,
  SelectDomainPage,
  PaymentPage,
  CompletePage,
} from 'pages';
import { ProviderUerRoles } from './publicRoutes/ProviderUerRoles';
import { default as RedirectPage } from 'pages/error/redirect';
import signOut from 'pages/auth/signOut';
import { getStorage } from 'helpers/storage';
import { setSubscription } from 'redux/actions/subscription.action';

const AppRoutes = () => {
  const dispatch = useDispatch();
  const query = useMedia('(max-width: 768px)');
  const { isMobile } = useSelector((state: any) => state.ui);
  const [compareScreen] = useState(isMobile !== query);

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    const subscription = getStorage('subscription');
    if (!!auth) dispatch(setAuth({ ...JSON.parse(auth), loading: false }));
    if (!!subscription) dispatch(setSubscription({ subscription }));
    else dispatch(setAuth({ loading: false }));
  }, []);

  useEffect(() => {
    if (compareScreen) dispatch(handleIsMobile(query));
  }, [compareScreen, dispatch, query]);

  return (
    <BrowserRouter>
      <Switch>
        {/*public Routes*/}
        <Route path="/login" component={AuthRoutes} />
        <Route path="/success-installation" component={SuccessInstallation} />
        <Route path="/not-access" component={NotAccess} />
        <Route path="/not-admin" component={NotAdmin} />
        <Route path="/not-installation" component={NotInstallationRoute} />
        <Route path="/signOut" component={signOut} />
        {/*Not access, complete register*/}
        <PrivateGoogleRoute path="/pricing" exact component={PricingPage} />
        <PrivateGoogleRoute
          isAdmin={false}
          path="/checkout"
          component={CheckoutRoute}
        />
        <PrivateGoogleRoute
          isAdmin={false}
          path="/payment"
          preCheck
          component={PaymentRoute}
        />
        <PrivateGoogleRoute
          isAdmin={false}
          path="/account/pricing"
          component={SelectDomainPage}
        />
        <PrivateGoogleRoute
          path="/account/complete"
          exact
          component={CompleteUserRoutes}
        />

        <PrivateRoute path="/redirect" component={RedirectPage} />
        <Route path="/*" component={All} />
      </Switch>
    </BrowserRouter>
  );
};

const CheckoutRoute = () => (
  <ProviderUerRoles>
    <CheckoutPage />
  </ProviderUerRoles>
);
const PaymentRoute = () => (
  <ProviderUerRoles image={1}>
    <PaymentPage />
  </ProviderUerRoles>
);
const NotInstallationRoute = ({ history }: any) => (
  <ProviderUerRoles avatar={false}>
    <NotInstallation history={history} />
  </ProviderUerRoles>
);
const CompleteUserRoutes = () => {
  return (
    <ProviderUerRoles image={2} avatar={false}>
      <CompletePage />
    </ProviderUerRoles>
  );
};
const All = () => <Redirect to="/login" />;

export default AppRoutes;
