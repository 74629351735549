import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { dataPricing } from 'utils/utils';
import useStateRedux from 'hooks/useStateRedux';
import React, { useEffect, useState } from 'react';
import CardPricing from 'components/card/cardPricing';
import { setGoogleAuthenticate } from '../../redux/actions/auth.action';

const ModalChangePlan = ({ ...props }: IProps) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const { google }: any = useStateRedux('auth');
  const [state, setState] = useState(dataPricing);
  const [currentPlan, setCurrentPlan] = useState({
    plan: '',
    currentMembership: '',
  });

  const handleActive = (title: string, currentMembership: string) => {
    setState(
      state.map((i: any) =>
        i.title === title ? { ...i, active: true } : { ...i, active: false },
      ),
    );
    setCurrentPlan({
      plan: title,
      currentMembership: currentMembership || 'yearly',
    });
    setDisabled(title === '' || title === 'Trial');
  };

  useEffect(() => handleActive(google.plan, google.currentMembership), []);

  const onOk = () => {
    dispatch(setGoogleAuthenticate(currentPlan));
    props.onCancel();
  };

  return (
    <Modal
      width={720}
      title="Select your plan"
      {...props}
      onOk={onOk}
      okButtonProps={{ disabled }}
    >
      <div className="beauty-scroll card__pricing-wrapper pt-5 pl-2">
        {state.map((i: any) => (
          <CardPricing
            {...i}
            key={i.id}
            footer={false}
            onClick={handleActive}
            head={(props: any) => <CardPricing.HeadPricing {...i} {...props} />}
          />
        ))}
      </div>
    </Modal>
  );
};

interface IProps {
  visible: boolean;
  onCancel: () => void;
}

export default ModalChangePlan;
