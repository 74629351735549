import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;
export const style = (index: number) => ({
  minWidth: 75,
  display: 'flex',
  paddingRight: 5,
  alignItems: 'center',
  justifyContent:
    index == 1 ? 'center' : index == 2 ? 'flex-end' : 'flex-start',
});
const RowData = ({
  list,
  colorType,
  small,
  indexsApply,
  dividerOut,
}: IProps) => {
  const color: any =
    colorType == -1 ? 'danger' : colorType == 1 ? 'success' : '';
  return (
    <>
      {!dividerOut && <Divider style={{ margin: small ? 10 : 15 }} />}
      <Row wrap={false}>
        {list.map((item, index) => (
          <Col key={index} span={8} style={style(index)}>
            {typeof item === 'symbol' ? (
              { item }
            ) : (
              <Text type={indexsApply?.includes(index) ? color : ''}>
                {item}
              </Text>
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

interface IProps {
  list: Array<React.ReactNode | string>;
  colorType?: number;
  small?: boolean;
  indexsApply?: number[];
  dividerOut?: boolean;
}
export default RowData;
