import React from 'react';

const Info = () => {
  return (
    <>
      <div className="mt-1">
        © 2021 Zumpul Signature |{' '}
        <a
          href="http://www.aeegle.com/tos"
          rel="noreferrer noopener"
          target="_blank"
        >
          Terms
        </a>
      </div>
      <div>
        <a
          href="http://zsupport.aeegle.com/submit-a-request"
          rel="noreferrer noopener"
          target="_blank"
        >
          Feedback
        </a>{' '}
        |{' '}
        <a
          href="http://zsupport.aeegle.com/"
          rel="noreferrer noopener"
          target="_blank"
        >
          Support
        </a>
      </div>
      <div>Accessibility</div>
    </>
  );
};

export default Info;
