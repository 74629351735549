import { ISignatureListModel } from 'models/api/signature.model';
import { ISuit } from 'models/api/auth.model';

export const initSignatureList: ISignatureListModel = {
  items: [],
  total_items: 0,
  pages: 1,
  current_page: 1,
};

export const initialSuitModel: ISuit = {
  admin_full_name: '',
  customer_id: '',
  admin_id: '',
  admin_email: '',
  licenses: 0,
  domains: [],
  number_of_employees: '',
};
