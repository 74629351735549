import { Button, Col, Grid, Row, Space, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import { useEffect, useState } from 'react';
import ModalChangePlan from 'components/modal/modalChangePlan';
import PlanInfo from './components/PlanInfo';
import { useDispatch } from 'react-redux';
import { setSubscription } from 'redux/actions/subscription.action';
import {
  getInvoicesSubscription,
  getSubscriptionById,
} from 'api/subscription/subscription.api';
import { useHistory } from 'react-router-dom';
import { getStorage, setStorage } from 'helpers/storage';
import getCurrentPayment from 'utils/getCurrentPayment';
import { getPayment } from 'api/payment/getPaymentURL';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const initialState = {
  data: null,
  error: false,
  Loading: true,
};

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const { edition, licenses, mailBox, google }: any = useStateRedux('auth');
  const TRIAL = edition === 'TRIAL' || edition === 'EXPIRED';
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [state, setState]: any = useState(initialState);

  /* A useEffect hook that is called when the edition changes. */
  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!TRIAL) {
        const { data, error } = await getSubscriptionById();
        !!error
          ? dispatch(setSubscription({ error: error }))
          : dispatch(
              setSubscription({ subscription: data, authorized: false }),
            );
        const { invoices, err } = await getInvoicesSubscription();
        !err ? dispatch(setSubscription({ invoices })) : null;
      }
      setLoading(false);
    })();
  }, [edition]);

  /* Calling the `getPayment` function and setting the state. */
  useEffect(() => {
    if (TRIAL && google.plan !== 'trial') {
      setState((prev: typeof state) => ({ ...prev, loading: true }));
      const plan = getCurrentPayment(google.plan, google.currentMembership);
      getPayment({ plan: plan }).then(({ data, error }) => {
        !error
          ? setState({ data, loading: false, error: false })
          : setState({ ...state, loading: false, error: error });
        setState((prev: typeof state) => ({ ...prev, loading: false }));
      });
    }
  }, [state.plan, google.plan, google.currentMembership]);

  const handleVisible = () => setVisible(!visible);

  const addLicenses = () => {
    const sub = getStorage('subscription');
    setStorage('subscription', { ...sub, authorized: true });
    history.push('/payment');
  };
  return (
    <>
      <div className="animate__animated animate__fadeIn text-center">
        {loading || state.loading ? (
          <Loading />
        ) : (
          <div style={{ margin: TRIAL ? '0' : '20px 40px' }}>
            <Row className={`${TRIAL ? 'mr-2' : ''} mt-2 mb-4`}>
              <Col
                md={20}
                xs={24}
                style={{
                  display: 'flex',
                  justifyContent: TRIAL ? 'center' : 'flex-start',
                }}
              >
                {TRIAL && !!state.data ? null : (
                  <Title level={2}>Information About the Zumpul Plan</Title>
                )}
              </Col>
              <Col
                md={4}
                xs={24}
                style={{
                  display: 'flex',
                  justifyContent: breakpoint.xs ? 'space-around' : 'flex-end',
                }}
              >
                <Space>
                  <Button
                    type={TRIAL ? 'primary' : 'default'}
                    disabled={!TRIAL}
                    onClick={handleVisible}
                  >
                    Change Plan
                  </Button>
                  <Button
                    type="primary"
                    disabled={TRIAL || mailBox <= licenses}
                    onClick={addLicenses}
                  >
                    Add licences
                  </Button>
                </Space>
              </Col>
            </Row>
            {TRIAL && !!state.data ? (
              <div
                style={{ height: 'calc(100vh - 162px)', overflowY: 'hidden' }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  src={state.data?.payment_uri || ''}
                />
              </div>
            ) : (
              <PlanInfo callback={handleVisible} />
            )}
          </div>
        )}
      </div>

      <ModalChangePlan visible={visible} onCancel={handleVisible} />
    </>
  );
};

export default CheckoutPage;
