import './styles.scss';
import { useGoogleApi } from 'react-gapi';
import { useDispatch } from 'react-redux';
import logout from 'config/google/logout';
import { scopes } from 'config/google/scopes';
import { Col, Grid, Row, Typography } from 'antd';
import Step from './components/Step';

const { Title } = Typography;
const { useBreakpoint } = Grid;
const NotInstallation = ({ history }: any) => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  const onBack = () => logout(auth, history, dispatch);

  return (
    <div className="text-center">
      <Title className="mt-3" style={{ fontSize: breakpoint.xs ? 32 : 64 }}>
        Installation Process
      </Title>
      <Row className="mt-5">
        <Col md={{ span: 11, offset: 1 }} xs={{ span: 22, offset: 2 }}>
          <Step />
        </Col>
        <Col md={{ span: 11, offset: 1 }} xs={{ span: 22, offset: 2 }}>
          <Step first={false} callback={onBack} />
        </Col>
      </Row>
    </div>
  );
};

export default NotInstallation;
