import { Card, Typography } from 'antd';
import RowData from 'components/rowsCard/RowData';
import { assets } from 'helpers/assets';
import useStateRedux from 'hooks/useStateRedux';
import { getExpCard } from 'utils/transform';
const { Title } = Typography;

const PaymentMethod = () => {
  const { subscription }: any = useStateRedux('subscription');
  const { card, customer } = subscription;

  return (
    <>
      <Title level={5}>Payment Method</Title>
      <Card className="card">
        <img src={assets.paypal} style={{ height: 50 }} />
        <RowData
          small
          list={[
            `XXXX XXXX XXXX ${card.last_four_digits}`,
            `${customer.first_name} ${customer.last_name}`,
            `Exp Date: ${getExpCard(card)}`,
          ]}
        />
      </Card>
    </>
  );
};
export default PaymentMethod;
