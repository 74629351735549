export const type = {
  authReset: '[auth] | reset',
  authSet: '[auth] | set',
  authLogin: '[auth] | login',
  authGoogleLogin: '[auth] | google login',
  uiAside: '[ui] | handle-aside',
  uiAsideHandleAnimation: '[ui] | handle-aside-animation',
  uiHandleIsMobile: '[ui] | handle-is-mobile',
  uiHandleOptionalLoading: '[ui] | handle-optional-loading',
  uiHandleLoading: '[ui] | handle-loading',

  uiHandleMenuAside: '[ui] | handle-menu-aside',

  formClean: '[form] | handle-clean-template',

  formHandleTemplate: '[form] | handle-template',
  formHandleEditTemplate: '[form] | handle-handle-edit-template',

  formHandleProfile: '[form] | handle-profile',

  formHandleSignature: '[form] | handle-signature',
  formHandleEditSignature: '[form] | handle-handle-edit-signature',
  formAddEntity: '[form] | handle-add-entity',

  formRemoveEntity: '[form] | handle-remove-entity',

  subscriptionSet: '[subscription] | set',
  subscriptionReset: '[subscription] | reset',

  stepSet: '[step] | set',
};
