import React from 'react';
import { Spin } from 'antd';

const Loading = () => {
  return (
    <div className="text-center" style={{ padding: 25 }}>
      <Spin size="large" />
    </div>
  );
};

export default Loading;
