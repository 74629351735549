import { Col, Grid, Row, Typography } from 'antd';
import { assets } from 'helpers/assets';
import CompleteForm from './components/completeForm';

const CompletePage = () => {
  const breakpoint = Grid.useBreakpoint();
  return (
    <div className="animate__animated animate__fadeIn">
      <Row>
        <Col
          hidden={(breakpoint.xs || breakpoint.sm) && !breakpoint.lg}
          span={12}
          style={{
            backgroundImage: `url(${assets.account})`,
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '91vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          <div>
            <Typography.Title
              style={{ color: '#FFFFFF', fontSize: 50, margin: 0 }}
            >
              Account
            </Typography.Title>
            <img src={assets.icon_account} height={200} />
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 12, offset: 0 }}
          className="p-4"
        >
          <CompleteForm />
        </Col>
      </Row>
    </div>
  );
};

export default CompletePage;
