import passportInstance from 'config/axios/v1Passport.instance';
import { getRefreshToken } from 'config/utils/refreshToken';
import { getStorage } from '../../helpers/storage';

const getToken = () => {
  const auth: any = getStorage('auth');
  if (!!auth?.token) return auth.token;
  return '';
};

const headers = () => ({
  Authorization: `Bearer ${getToken()}`,
  Accept: 'application/json',
});

export const getPayment = async (body: any, attemps = 0): Promise<any> => {
  try {
    const { data } = await passportInstance.post(`/payments/calculate`, body, {
      headers: headers(),
    });
    return { data, error: null };
  } catch (error: any) {
    if (error?.response?.status === 401 && attemps < 1) {
      return getRefreshToken()
        .then(() => getPayment(body, ++attemps))
        .catch(() => {
          return { data: null, error };
        });
    }
    return { data: null, error };
  }
};
