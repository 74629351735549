import moment from 'moment-timezone';
import { ITemplateFormModel } from 'models/form.model';

const edit = {
  id: null,
  editing: false,
};

export const template = {
  edit,
  id: null,
  save: false,
  name: '',
  summary: '',
  body: '',
  enable: '',
};

export const createTemplateData = {
  name: '',
  summary: '',
  body: '',
};

export const createSignatureData = {
  name: '',
  template_id: null,
  body: '',
  timezone: '',
  created_by: '',
  execution_date: '',
  sync_hours: '',
  policy_id: null,
  primary_signature: 0,
  active: 0,
  sync_interval: null,
  entities: [],
};

export const signature = {
  edit,
  save: false,
  id: null,
  name: '',
  template_id: null,
  body: '${firstName} ${lastName} | Email: ${primaryEmail}\n' + '${company}',
  timezone: moment.tz.guess(),
  created_by: '',
  execution_date: moment().toISOString(),
  execution_type: '',
  sync_hours: moment().toISOString(),
  policy_id: null,
  primary_signature: 0,
  active: 0,
  sync_interval: null,
  entities: [],
};

const profile = {
  kind: '',
  id: '',
  etag: '',
  primaryEmail: '',
  name: {
    givenName: '',
    familyName: '',
    fullName: '',
  },
  isAdmin: false,
  isDelegatedAdmin: false,
  lastLoginTime: '',
  creationTime: '',
  agreedToTerms: false,
  suspended: false,
  archived: false,
  changePasswordAtNextLogin: false,
  ipWhitelisted: false,
  ims: [],
  emails: [],
  externalIds: [],
  relations: [],
  addresses: [],
  organizations: [],
  phones: [],
  aliases: [],
  notes: {
    value: '',
    contentType: '',
  },
  websites: [],
  gender: {
    type: '',
  },
  customerId: '',
  orgUnitPath: '',
  isMailboxSetup: false,
  isEnrolledIn2Sv: false,
  isEnforcedIn2Sv: false,
  includeInGlobalAddressList: false,
  thumbnailPhotoUrl: '',
  thumbnailPhotoEtag: '',
  recoveryEmail: '',
};

const outOffice = {
  id: null,
  title: '',
  description: '',
  messageSubject: '',
  messageBody: '',
  entities: [],
  enable: false,
  start_date: '',
  end_date: '',
  enable_end_date: false,
  only_contact: false,
  only_organization: false,
};

export const formInitialState: ITemplateFormModel = {
  template,
  signature,
  profile,
  outOffice,
};
