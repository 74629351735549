import React from 'react';
import { LoginPage } from 'pages';
import { Route, Switch } from 'react-router-dom';

const AuthRoutes = () => (
  <Switch>
    <Route path="/login" component={LoginPage} />
  </Switch>
);

export default AuthRoutes;
