import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import useStateRedux from '../../hooks/useStateRedux';
import Loading from '../../components/loading/loading';

interface IProps {
  component: any;
  path: string;

  [key: string]: unknown;
}

const PrivateRoute = ({ component: Component, ...rest }: IProps) => {
  const { authenticated, loading }: any = useStateRedux('auth');
  const history = useHistory();

  if (loading) return <Loading />;
  if (!authenticated) history.push('/login');

  return (
    <Route
      {...rest}
      render={(props: any) =>
        authenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
