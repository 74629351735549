/* eslint-disable no-template-curly-in-string */

export const dataPricingSimplify: any = [
  {
    id: 'trial',
    title: 'Trial',
    href: '/account/complete',
    head: '15 days trial',
    okText: 'Get Free Trial',
    pricing: {
      monthly: {
        integer: 0,
        decimal: 0,
      },
      annually: {
        integer: 0,
        decimal: 0,
      },
    },
    image: '/Layout.svg',
    advantage: [
      'Signature Management',
      '20 Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
  {
    id: 'pro',
    title: 'Pro',
    href: '/account/complete',
    okText: 'Buy Now!',
    isFeature: true,
    pricing: {
      monthly: {
        integer: 0,
        decimal: 55,
      },
      annually: {
        integer: 5,
        decimal: 50,
      },
    },
    advantage: [
      'Signature Management',
      '20 Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
  {
    id: 'enterprise',
    title: 'Enterprise',
    okText: 'Buy Now!',
    href: '/account/complete',
    pricing: {
      monthly: {
        integer: 0,
        decimal: 70,
      },
      annually: {
        integer: 7,
        decimal: 20,
      },
    },
    advantage: [
      'Signature Management',
      'Unlimited Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
  {
    id: 'edu',
    title: 'Education',
    head: 'Contact Us',
    image: '/Typography.svg',
    okText: 'Contact us',
    href: '/account/complete',
    pricing: {
      monthly: {
        integer: 0,
        decimal: 0,
      },
      annually: {
        integer: 0,
        decimal: 0,
      },
    },
    advantage: [
      'Signature Management',
      'Unlimited Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
];

export const dataPricing = [
  {
    id: 'pro',
    title: 'Pro',
    href: '',
    okText: 'Buy Now!',
    active: true,
    isFeature: true,
    pricing: {
      monthly: {
        integer: 0,
        decimal: 55,
      },
      annually: {
        integer: 5,
        decimal: 50,
      },
    },
    advantage: [
      'Signature Management',
      '20 Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Google Profile Auto Sync',
      'Support & Training 24/7',
      'Implementation consulting',
      'User Deprovisioning (Roadmap)',
      'Shared Contacts (Roadmap)',
    ],
  },
  {
    id: 'enterprise',
    title: 'Enterprise',
    okText: 'Buy Now!',
    active: false,
    href: '',
    pricing: {
      monthly: {
        integer: 0,
        decimal: 70,
      },
      annually: {
        integer: 7,
        decimal: 20,
      },
    },
    advantage: [
      'Signature Management',
      'Unlimited Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Google Profile Auto Sync',
      'Support & Training 24/7',
      'Implementation consulting',
      'User Deprovisioning (Roadmap)',
      'Shared Contacts (Roadmap)',
    ],
  },
];

export const editorCustomField = [
  {
    id: 'firstName',
    tooltip: 'Insert First Name in the editor',
    title: 'First Name',
    insert: '${firstName}',
  },
  {
    id: 'lastName',
    tooltip: 'Insert Last Name in the editor',
    title: 'Last Name',
    insert: '${lastName}',
  },
  {
    id: 'primaryEmail',
    tooltip: 'Insert Email in the editor',
    title: 'Email',
    insert: '${primaryEmail}',
  },
  {
    id: 'title',
    tooltip: 'Insert Title in the editor',
    title: 'Title',
    insert: '${title}',
  },
  {
    id: 'manager',
    tooltip: 'Insert Manager in the editor',
    title: 'Manager',
    insert: '${manager}',
  },
  {
    id: 'department',
    tooltip: 'Insert Department in the editor',
    title: 'Department',
    insert: '${department}',
  },
  {
    id: 'company',
    tooltip: 'Insert Company Name in the editor',
    title: 'Company',
    insert: '${company}',
  },
];

export const dataFrequencySignature = [
  {
    key: 'annual',
    label: 'Annual',
    value: 6,
  },
  {
    key: 'weekly',
    label: 'Weekly',
    value: 2,
  },
  {
    key: 'monthly',
    label: 'Monthly',
    value: 4,
  },
  {
    key: 'quarterly',
    label: 'Quarterly',
    value: 5,
  },
  {
    key: 'daily',
    label: 'Daily',
    value: 10,
  },
];

export const weekDays = [
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '7', label: 'Sunday' },
];

export const IMKeys = [
  { value: 'aim', label: 'AIM' },
  { value: 'gtalk', label: 'Google Talk' },
  { value: 'icq', label: 'ICQ' },
  { value: 'jabber', label: 'Jabber' },
  { value: 'msn', label: 'MSN' },
  { value: 'net_meeting', label: 'Net Meeting' },
  { value: 'qq', label: 'QQ' },
  { value: 'skype', label: 'Skype' },
  { value: 'yahoo', label: 'Yahoo' },
];

export const TypeEmailKeys = [
  { value: 'work', label: 'Work' },
  { value: 'home', label: 'Home' },
  { value: 'custom', label: 'Custom' },
];
