export default function getCurrentPayment(plan: string, current: string) {
  const currentTime = current === 'annually' ? 'yearly' : current;
  return `${plan.toLowerCase()}_${currentTime}`;
}
export function getPlan(plan: string) {
  if (plan.includes('ZPRO')) return 'pro';
  else if (plan.includes('ZENT')) return 'enterprise';
  else if (plan.includes('EXP')) return 'expired';
  return 'trial';
}

export function getCurrentMembership(plan: string) {
  const split = plan.split('1')[1];
  if (split === 'M') {
    return 'monthly';
  }
  return 'annually';
}
