import passportInstance from 'config/axios/v1Passport.instance';
import { getRefreshToken } from 'config/utils/refreshToken';
import { getStorage, setStorage } from '../../helpers/storage';
const getToken = () => {
  const auth: any = getStorage('auth');
  if (!!auth?.token) return auth.token;
  return '';
};

const headers = () => ({
  Authorization: `Bearer ${getToken()}`,
  Accept: 'application/json',
});

export const getSubscriptionById = async (attemps = 0): Promise<any> => {
  try {
    const { data } = await passportInstance.get<any>('/subscription', {
      headers: headers(),
    });
    setStorage('subscription', data);
    return { data, error: null };
  } catch (error: any) {
    if (error?.response?.status === 401 && attemps < 1) {
      return getRefreshToken()
        .then(() => getSubscriptionById(++attemps))
        .catch(() => {
          return { data: null, error };
        });
    }
    return { data: null, error };
  }
};
export const getInvoicesSubscription = async (): Promise<any> => {
  try {
    const { data } = await passportInstance.get<any>('/subscription/invoices', {
      headers: headers(),
    });
    return { invoices: data, err: null };
  } catch (err) {
    return { invoices: null, err };
  }
};
export const upgradeSubscription = async (): Promise<any> => {
  const { data } = await passportInstance.put<any>(
    '/subscription',
    {},
    {
      headers: headers(),
    },
  );
  return { data, error: null };
};
