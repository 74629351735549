import React from 'react';
import { Typography } from 'antd';
import CardPricing from 'components/card/cardPricing';

const { Title } = Typography;

const SelectPlan = ({ data }: IState) => (
  <div>
    <div className="mt-2">
      <Title level={5}>Choose a plan:</Title>
      <div className="beauty-scroll card__pricing-wrapper pt-5">
        {data.map((i: any) => {
          return (
            <CardPricing
              key={i.id}
              {...i}
              head={(props: any) => (
                <CardPricing.HeadPricing {...i} {...props} />
              )}
            >
              <CardPricing.CardList data={i.advantage} />
            </CardPricing>
          );
        })}
      </div>
    </div>
  </div>
);

interface IState {
  data: any;
  handleActive?: (id: any) => void;
}

export default SelectPlan;
