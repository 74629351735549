import { type } from '../types/index.type';
import { IActionRedux } from 'models/global.model';
import { ITemplateFormModel } from 'models/form.model';
import { formInitialState } from '../initialState/form-initial-state';

const formReducer = (
  state: ITemplateFormModel = formInitialState,
  action: IActionRedux,
) => {
  switch (action.type) {
    case type.formHandleTemplate:
      return { ...state, template: { ...state.template, ...action.payload } };

    case type.formHandleEditTemplate:
      return {
        ...state,
        template: { ...state.template, edit: action.payload, save: false },
      };

    case type.formClean:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const form = formInitialState[action.payload];
      return {
        ...state,
        [action.payload]: { ...form, save: false },
      };

    // Signature reducer
    case type.formHandleSignature:
      return { ...state, signature: { ...state.signature, ...action.payload } };

    case type.formHandleEditSignature:
      return {
        ...state,
        signature: { ...state.signature, edit: action.payload, save: false },
      };

    case type.formAddEntity:
      const findEntity = state.signature.entities.find(
        (e) => e.id === action.payload.id,
      );
      let newEntities = state.signature.entities;
      if (!findEntity)
        newEntities = [...state.signature.entities, action.payload];
      return {
        ...state,
        signature: { ...state.signature, entities: newEntities },
      };

    case type.formRemoveEntity:
      const entities = state.signature.entities.filter(
        (i) => i.id !== action.payload,
      );
      return { ...state, signature: { ...state.signature, entities } };

    // Profile reducer
    case type.formHandleProfile:
      return { ...state, profile: { ...state.profile, ...action.payload } };

    default:
      return state;
  }
};

export default formReducer;
