import React from 'react';
import { assets } from 'helpers/assets';
import VideoIntroduction from './videoIntroduction';

const MobileWrapper = () => {
  return (
    <div
      className="auth__mobile-wrapper animate__animated animate__fadeInDown animate__delay-1s"
      style={{ backgroundImage: `url('${assets.zumpul_bg_auth}')` }}
    >
      <VideoIntroduction mobile />
    </div>
  );
};

export default MobileWrapper;
