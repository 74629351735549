// @ts-ignore
import countryList from 'react-select-country-list';
import { useGoogleApi } from 'react-gapi';
import logout from 'config/google/logout';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import PhoneInput from 'react-phone-input-2';
import { scopes } from 'config/google/scopes';
import { useHistory } from 'react-router-dom';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import useFetchSuit from 'api/authAndCustomer/verifySuit.api';
import React, { useEffect, useState } from 'react';
import createdCustomer from 'api/authAndCustomer/actionsCustomer.api';
import { Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';
import {
  setAuthenticate,
  setGoogleAuthenticate,
} from 'redux/actions/auth.action';
import { companyFields, contactFields } from 'utils/fieldsForm';
import CountryInput from './CountryInput';

const CompleteForm = () => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes: scopes });
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const { google }: any = useStateRedux('auth');
  const auth = gapi?.auth2?.getAuthInstance();

  const { data, error, loading } = useFetchSuit();
  const [country, setCountry] = useState([]);
  const [saving, setSaving] = useState(false);
  const onFinish = async ({
    country,
    first_name,
    last_name,
    ...values
  }: any) => {
    setSaving(true);
    values['admin_full_name'] = `${first_name} ${last_name}`;
    const newValues = { ...data, ...values, ...JSON.parse(country) };
    const { data: user } = await createdCustomer(newValues);

    dispatch(
      setAuthenticate({
        ...user,
        authenticated: true,
        google: { ...newValues },
      }),
    );
    setSaving(false);
    google.plan === 'Trial'
      ? history.push('/success-installation')
      : history.replace('/checkout');
  };

  /* Setting the country list to the state. */
  useEffect(() => {
    setCountry(countryList().getData());
  }, []);

  /* Setting the form values to the data that is returned from the API. */
  useEffect(() => {
    if (data) {
      const [first_name, last_name] = data.admin_full_name.split(' ');
      const copy = {
        first_name,
        last_name,
      };
      Object.assign(copy, data);
      dispatch(setGoogleAuthenticate(data));
      form.setFieldsValue(copy);
    }
  }, [data]);

  /* Checking if there is an error, if there is, it will logout the user. */
  useEffect(() => {
    if (!!error) logout(auth, history, dispatch);
  }, [error]);

  /* Checking if the data is loading or saving, if it is, it will return the loading component. */
  if (loading || saving) {
    return <Loading />;
  }

  return (
    <Card className="card" style={{ backgroundColor: '#FFFFFF' }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="complete-form"
      >
        <Row className="mt-4" gutter={20}>
          <Col span={24}>
            <Typography.Title level={3}>Contact Information</Typography.Title>
          </Col>
          {contactFields.map((x: any, index: number) => (
            <Col md={12} xs={24} key={index}>
              <Form.Item
                label={x.label}
                required={x.required}
                name={x.name}
                rules={x.rules}
              >
                {x.phone ? (
                  <PhoneInput
                    country={'us'}
                    inputStyle={{ width: '100%', backgroundColor: '#EBEBEB' }}
                  />
                ) : x.country ? (
                  <CountryInput
                    countries={country}
                    style={{ backgroundColor: '#EBEBEB' }}
                  />
                ) : (
                  <Input
                    placeholder={x.placeHolder}
                    size="large"
                    style={{ backgroundColor: '#EBEBEB' }}
                  />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row className="mt-4" gutter={20}>
          <Col span={24}>
            <Typography.Title level={3}>Company Information</Typography.Title>
          </Col>
          {companyFields.map((x: any, index: number) => (
            <Col md={x.block ? 24 : 12} xs={24} key={index}>
              <Form.Item
                label={x.label}
                required={x.required}
                name={x.name}
                rules={x.rules}
              >
                {x.select ? (
                  <Select
                    style={{ backgroundColor: '#EBEBEB' }}
                    size="large"
                    placeholder="Select an option"
                  >
                    {x.list.map((item: string, i: number) => (
                      <Select.Option key={i} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    placeholder={x.placeHolder}
                    style={{ backgroundColor: '#EBEBEB' }}
                    size="large"
                  />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row justify="end">
          <Button
            size="large"
            type="primary"
            className="mt-2"
            htmlType="submit"
            form="complete-form"
            style={{ width: 200 }}
          >
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default CompleteForm;
