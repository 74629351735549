import { ITypeAuthAction } from 'models/global.model';
import { type } from '../types/index.type';

const init = {
  error: false,
  loading: false,
  invoices: [],
  subscription: {
    subscription_id: '',
    name: '',
    status: 'live',
    sub_total: 0,
    amount: 0,
    last_billing_at: '2022-05-06',
    next_billing_at: '2023-05-06',
    billing_mode: '',
    auto_collect: true,
    plan: {
      plan_code: '',
      name: '',
      description: '',
      price: 0.0,
      quantity: 0,
      discount: 0,
      total: 0,
    },
    coupon: {
      coupon_code: '',
      discount_amount: 0,
    },
    customer_id: '',
    customer: {
      customer_id: '',
      display_name: '',
      company_name: '',
      first_name: '',
      last_name: '',
      salutation: '',
      email: '',
    },
    card: {
      card_id: '',
      last_four_digits: '',
      expiry_month: 0,
      expiry_year: 0,
      funding: '',
      payment_gateway: '',
      gateway_card_id: '',
      gateway_customer_id: '',
    },
  },
};

const SubscriptionReducer = (
  state: typeof init = init,
  action: ITypeAuthAction,
) => {
  switch (action.type) {
    case type.subscriptionSet:
      return { ...state, ...action.payload, loading: false };
    case type.subscriptionReset:
      return init;
    default:
      return state;
  }
};

export default SubscriptionReducer;
