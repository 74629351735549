import React from 'react';
import { Button } from 'antd';
import { assets } from 'helpers/assets';

const ButtonGoogle = (props: any) => {
  return (
    <Button
      {...props}
      style={{ borderRadius: 8 }}
      className="social__google-btn"
      icon={
        <img
          className="mr-1"
          src={assets.google_icon}
          alt="google img"
          width={18}
        />
      }
    >
      <span>Signed in with Google</span>
    </Button>
  );
};

export default ButtonGoogle;
