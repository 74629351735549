import { Button, Card, Col, Divider, Grid, Row, Space, Typography } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setStep } from 'redux/actions/step.action';
import { getLargeDate, getMount, getPriceLicense } from 'utils/subscriptions';
import { getExpCard } from 'utils/transform';
import { openMessage } from './confirmation/message';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const ConfirmPayment = () => {
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const history = useHistory();
  const { mailBox, licenses }: any = useStateRedux('auth');
  const { subscription }: any = useStateRedux('subscription');
  const { customer, card, last_billing_at, next_billing_at } = subscription;
  const price = getPriceLicense(
    next_billing_at,
    subscription.plan.price,
    subscription.interval_unit,
  );
  return (
    <Row className="text-center">
      <Col xs={24} md={{ span: 14, offset: 5 }}>
        <Card
          className="text-left card"
          style={{ padding: breakpoint.xs ? 1 : 20 }}
        >
          <Title level={2}>Subscription Preview</Title>
          <Row>
            {/* first column */}
            <Col md={14} xs={24}>
              <Space direction="vertical">
                <Space direction="vertical">
                  <Text strong>Company name/Person</Text>
                  <Text italic>
                    <strong>{customer.company_name}</strong>/
                    {customer.first_name} {customer.last_name}
                  </Text>
                </Space>
                <Space direction="vertical" size={1} className="mt-3">
                  <Text strong>Credit card number</Text>
                  <Text>XXXX XXXX XXXX {card.last_four_digits}</Text>
                  <Text>Card expires on {getExpCard(card)}</Text>
                  <Text>Gateway - {card.payment_gateway}</Text>
                  <Text>Funding - {card.funding}</Text>
                </Space>
              </Space>
            </Col>
            {/* seconds col */}
            <Col md={10} xs={24} className={breakpoint.xs ? 'mt-4' : ''}>
              <Space direction="vertical">
                <Text strong>Email</Text>
                <Text>{customer.email}</Text>
              </Space>
              <Space direction="vertical" size={1} className="mt-3">
                <Space direction="vertical">
                  <Text strong>Last payment</Text>
                  <Text>{getLargeDate(last_billing_at)}</Text>
                </Space>
                <Space direction="vertical" className="mt-1" size={1}>
                  <Text strong>New Recurring subscription Charge</Text>
                  <Text>
                    {`On ${getLargeDate(next_billing_at)}. `}
                    <strong>
                      {getMount(mailBox, subscription.plan.price)}
                    </strong>
                  </Text>
                </Space>
              </Space>
            </Col>
          </Row>
          {/* footer */}
          <Row justify="space-between" className="mt-5">
            <Col md={20} xs={24}>
              <Space direction="vertical" size={1}>
                <Title level={4} style={{ margin: 0 }}>
                  Inmediate Subscription Charge
                </Title>
                {!breakpoint.xs && <Text>Payment for remaining days</Text>}
              </Space>
            </Col>
            <Col md={4} xs={24} className={breakpoint.xs ? 'mt-2' : ''}>
              <Space direction="vertical" size={1}>
                <Title level={4} style={{ margin: 0 }}>
                  {getMount(mailBox - licenses, price)}
                </Title>
                <Text>On {getLargeDate(new Date())}</Text>
              </Space>
            </Col>
          </Row>
          <Divider style={{ borderTop: '2px solid #000000' }} />
          <Row justify="end">
            <Button
              type="primary"
              onClick={openMessage(() => {
                dispatch(setStep(2));
                history.push('/payment/confirmation');
              })}
            >
              Confirm
            </Button>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default ConfirmPayment;
