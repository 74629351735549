import { type } from 'redux/types/index.type';
import { IReduxState } from 'models/global.model';

export const handleOpenAside =
  () => (dispatch: any, getState: () => IReduxState) => {
    const { ui } = getState();
    dispatch({ type: type.uiAsideHandleAnimation });
    if (ui.aside) setTimeout(() => dispatch({ type: type.uiAside }), 200);
    else dispatch({ type: type.uiAside });
  };
export const handleMenuAside = (payload: IHandleMenu) => (dispatch: any) =>
  dispatch({ type: type.uiHandleMenuAside, payload });

export const handleIsMobile = (payload: any) => (dispatch: any) =>
  dispatch({ type: type.uiHandleIsMobile, payload });

export const uiHandleLoading = () => (dispatch: any) =>
  dispatch({ type: type.uiHandleLoading });
export const uiHandleOptionalLoading = (payload?: boolean) => (dispatch: any) =>
  dispatch({
    type: type.uiHandleOptionalLoading,
    payload,
  });

interface IHandleMenu {
  selectedKeys?: string[];
  openKeys?: string[];
}
